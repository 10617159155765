@use 'src/src/app/scss/abstracts' as *;

.products-carousel {
  //   &#{$block-name} {
  //   }

  $block-name: &;

  overflow: hidden;

  &__inner-wrap {
    position: relative;
    margin-left: -3px;
    margin-right: -3px;
    z-index: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }

  &__inner,
  swiper-container {
    display: flex !important;
    flex-wrap: nowrap !important;
  }

  &__cell,
  swiper-slide {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto !important;
    flex-direction: column;
    flex-grow: 1;
    width: 50% !important;
    padding-left: 3px;
    padding-right: 3px;
  }
}
